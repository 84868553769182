// ** Next imports
import type { AppProps } from "next/app";
import Router, { useRouter } from "next/router";
import Script from "next/script";

// ** File imports
import nProgress from "nprogress";
import { fbScriptData, fbPageview } from "../lib/FbPixel";
import { gtmScriptData, gtmPageView } from "../lib/GoogleTagManager";

// ** Styles
import "animate.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-svg-core/styles.css";

import "../styles/globals.css";
import "../styles/nprogress.css";

import { newEvent } from "../helpers/events";
import { useEffect } from "react";

async function pageTrack(path: string) {
  return await newEvent("VISIT_PAGE", {
    page: path,
  });
}

// ** nProgress
Router.events.on("routeChangeStart", nProgress.start);
Router.events.on("routeChangeError", nProgress.done);
Router.events.on("routeChangeComplete", (changedToPath: string) => {
  nProgress.done();
  fbPageview();
  gtmPageView(changedToPath);
});

export default function App({ Component, pageProps }: AppProps) {
  const router = useRouter(),
    currentUrl = router.asPath;

  useEffect(() => {
    pageTrack(currentUrl);
  }, [currentUrl]);

  return (
    <>
      {/* Facebook Pixel */}
      <Script
        id="fb-pixel"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: fbScriptData,
        }}
      />
      {/* Google Tag Manager */}
      <Script
        id="gtag-base"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: gtmScriptData,
        }}
      />
      <Component {...pageProps} />;
    </>
  );
}
